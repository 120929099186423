import _isEqual from 'lodash/isEqual';

export default {
    name: 'mwc-config-mixin',
    data() {
        return {
            options: {},
        };
    },
    created() {
        const getDefaultValue = settingKey => {
            let defaultValue;
            if (this.settings[settingKey] === undefined) {
                // if there is no setting value then use the prop value
                defaultValue = this[settingKey];
            } else {
                // if the setting value is different to the default config value then use that (a config override takes precedence over props)
                const defaultConfigValue =
                    this.defaultConfig && this.defaultConfig.settings && this.defaultConfig.settings[settingKey];
                if (!_isEqual(this.settings[settingKey], defaultConfigValue)) {
                    defaultValue = this.settings[settingKey];
                } else if (this.$options.propsData[settingKey] !== undefined) {
                    // if there is an attribute set on the element then use that value
                    defaultValue = this[settingKey];
                } else {
                    // else use the config value
                    defaultValue = this.settings[settingKey];
                }
            }
            return defaultValue;
        };

        // Loop through the settings and find matching props
        // Set up a reactive property under this.options for each one and add a watch
        // on the prop to keep this.options.xxx updated
        Object.keys(this.settings).forEach(settingKey => {
            // eslint-disable-next-line no-underscore-dangle
            if (this.$options._propKeys.indexOf(settingKey) > -1) {
                this.$set(this.options, settingKey, getDefaultValue(settingKey));
                // todo: we could add a way to prevent watchers being automatically created for all props if necessary
                this.$watch(settingKey, function updateValue(newValue) {
                    this.options[settingKey] = newValue;
                });
            }
        });
    },
};
