/**
 * Get a namespaced elementId based on the ancestors' mwcIds
 * @param {string} id - id to be namespaced
 * @returns - namespaced id
 */
function getNamespaceId(mwcId, id) {
    const newId = mwcId.concat('-', id || 'empty');
    return newId.replace(/\./g, '-');
}

export default {
    getNamespaceId,
};
